import React, { useCallback, useEffect, useRef, useState } from "react";
import { apiUser } from "../../Apis/configs/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import { SpinnerCircular } from "spinners-react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Chip,
  IconButton,
  Stack,
  Paper,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { blue, blueGrey, green, grey, yellow } from "@mui/material/colors";
import { ArrowBack } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import SaveIcon from "@mui/icons-material/Save";
import LinearProgress from "@mui/material/LinearProgress";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: "8px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  padding: "20px",
  textAlign: "center",
};

const labelStyle = {
  fontSize: "18px",
  fontWeight: "normal",
  marginBottom: "10px",
  color: "#032C65",
};

export const ZonesTable = () => {
  const [zones, setZones] = useState([]);
  const [editedZones, setEditedZones] = useState([]);
  const [selectedDecorerTypes, setSelectedDecorerTypes] = useState([]);
  const [removeState,setRemoveState] = useState(false)
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const columns = [
    {
      field: "zone_type",
      headerName: t("Decoder Type"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      align: "left",
      headerClassName: "header-table-title-style",
      renderCell: ({ row }) => {
        let label = "";
        switch (row.zone_type) {
          case 1:
            label = t("Zone");
            break;
          case 2:
            label = t("MV/PS");
            break;
          case 3:
            label = t("Flow meter");
            break;
          case 4:
            label = t("Moisture sensor");
            break;

          default:
        }
        return row.decoder !== "000000" ? (
          <Chip
            label={label}
            size="small"
            sx={{ backgroundColor: green[100] }}
          />
        ) : (
          <Chip
            label="N/A"
            size="small"
            sx={{
              backgroundColor: grey[200],
              color: grey[600],
            }}
          />
        );
      },
    },
    {
      field: "zone_name",
      headerName: t("Name"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
    },
    {
      field: "decoder",
      headerName: t("Decoder S/N"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
    },
    {
      field: "zone_wire",
      headerName: t("Wire"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
    },
    {
      field: "edit",
      headerName: t("Actions"),
      headerClassName: "header-table-title-style",
      renderCell: (params) => (
        <><Button
          startIcon={<EditIcon />}
          style={{ alignContent: "flex-start", color: "#032C65" }}
          onClick={() => editZone(params.row)}
        ></Button><Button
          style={{ border: "none", width: "50%" }}
          onClick={() => deactivateZone(params.row)}
          variant="outlined"
          startIcon={<RemoveCircleOutlineIcon style={{  marginRight:34,  color: "#800000" }} />}
        ></Button></>
      ),
    },
  ];
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    decoder: "000000",
    wire: 1,
    zone_type: 1,
  });
  const [loadingBox, setloadingBox] = useState(false);
  const [loadingProgressBar, setLoadingProgressBar] = useState(false);
  let [ProgressBarSteps, setProgressBarSteps] = useState(null);
  const [progressBarColor, setProgressBarColor] = useState("primary");
  const navigate = useNavigate();
  const [changedZones, setChangedZones] = useState(false);
  const [zonesData, setZonesData] = useState([]);

  const openBox = () => setOpen(true);

  const closebox = () => setOpen(false);

  useEffect(() => {
    getZones();
  }, []);

  const prevZonesDataRef = useRef();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await apiUser.get(
          `/controllers/${location.state.id}/realtime`
        );
        const newZonesData = resp.data.data.loading_state_change;
        if (newZonesData !== null) {
          setloadingBox(true);
          setZonesData(newZonesData);
          setProgressBarSteps((prevSteps) => Math.min(prevSteps + 1, 3));
          prevZonesDataRef.current = newZonesData;
        } else if (newZonesData === null && ProgressBarSteps === 0) {
          setloadingBox(true);
        } else if (newZonesData == null && ProgressBarSteps > 0) {
          setProgressBarColor("success");

          setTimeout(() => {
            setLoadingProgressBar(false);

            setloadingBox(false);
            setProgressBarSteps(null);

            setLoadingProgressBar(false);
          }, 2000);
        }
      } catch (e) {
        console.error(e);
        setLoadingProgressBar(false);
      }
    };

    const timer = setInterval(fetchData, 6000);
    return () => {
      clearInterval(timer);
    };
  }, [ProgressBarSteps]);
  const getZones = async () => {
    setIsLoading(true);
    try {
      const zonesResponse = await apiUser.get(
        `/controllers/${location.state.id}`
      );

      const zonesData = zonesResponse.data.data.zones;
      const zonesWithIds = zonesData.map((zone, index) => ({
        ...zone,
        id: index + 1,
        zone_name: zone.zone_name || t(`Zone`) + ` ${index + 1}`,
      }));

      if (JSON.stringify(zonesWithIds) !== JSON.stringify(zones)) {
        setZones(zonesWithIds);
      }
    } catch (error) {
      console.error(error);
      if (error) {
        return Swal.fire({
          title: "Error",
          text: t("Error occured, back to my controllers."),
          icon: "warning",
          timer: 2000,
        }).then(() => {
          navigate("/mycontrollers");
        });
      }
    }
    setIsLoading(false);
  };
  const editZone = (item) => {
    openBox();
    setSelectedRow(item);

    setFormData({
      zone_number: item.id,
      name: item.zone_name || "",
      decoder: item.decoder || "",
      wire: item.zone_wire || "",
      zone_type: item.zone_type || '1',
      assigned_pumps: [true, true, false, true, false],
    });
  };
  const confirmEditZoneTime = () => {
    const newRunTimeInfo = { ...formData };
   

    if(newRunTimeInfo.decoder == "000000" ){
      closebox();
      Swal.fire({
        title:t('Edit Zone'),
        text: t('You need to set a valid decoder'),
        icon:'info',
        confirmButtonColor:'#032C65',
        confirmButtonText:t('Confirm')
        
      })
      return
    }

    const isDecoderInUse = zones.some(zone => zone.decoder === newRunTimeInfo.decoder && zone.id !== selectedRow.id);
  if (isDecoderInUse) {
    closebox();
    Swal.fire({
      title: t('Edit Zone'),
      text: t('This decoder is already in use in another zone'),
      icon: 'warning',
      confirmButtonColor: '#032C65',
      confirmButtonText: t('Confirm')
    });
    return;
  }
    
    else{
      const newArr = [
        newRunTimeInfo,
        ...editedZones.filter((item) => {
          return item.zone_number !== newRunTimeInfo.zone_number;
        }),
      ];
  
      setEditedZones(newArr);
      const index = zones.findIndex((zone) => zone.id === selectedRow.id);

      if (index !== -1 ) {
        const updatedZone = {
          ...zones[index],
          zone_name: formData.name,
          decoder: formData.decoder,
          zone_wire: formData.wire,
          zone_type: formData.zone_type,
        };
        const updatedZones = [...zones];
        updatedZones[index] = updatedZone;
        setZones(updatedZones);
  
        setChangedZones(true);
      }
      setChangedZones(true);
      closebox();
    }
   
  };
  const deactivateZone = (zone) => {
    setSelectedRow(zone);
  
    if (zone) {
      Swal.fire({
        title: t('Deactivate Zone'),
        text: t(`Are you sure to deactivate `) + `${zone.zone_name}`  ,
        icon: 'question',
        confirmButtonColor: '#d33',
        confirmButtonText: t('Deactivate'),
        showCancelButton: true,
        cancelButtonColor: '#032C65',
        cancelButtonText: t('Cancel'),
      }).then((data) => {
        if (data.isConfirmed) {
            setRemoveState(true)
          let obj = {
            zone_number: zone.id,
            name: t('Zone') + ` ${zone.id}`,
            decoder: "000000",
            wire: 1,
            zone_type: 1,
            assigned_pumps: [true, true, false, true, false],
          }

          setRemoveState(true);
  
          if (zone.decoder === "000000") {
            return Swal.fire({
              title: t('Zone already deactivated'),
              text: t('This zone has already been deactivated.'),
              icon: 'info',
              confirmButtonColor: '#032C65',
              confirmButtonText: t('Confirm'),
            });
          }
          else {
            Swal.fire({
              title: t('Zone Deactivated'),
              text: t('This zone has been deactivated and reset to default settings.'),
              icon: 'success',
              confirmButtonColor: '#032C65',
              confirmButtonText: t('Confirm'),
            }).then((data)=> {
              
              if(data.isConfirmed){


                setChangedZones(true);
                const newRunTimeInfo = { ...obj };
      
                const newArr = [
                  newRunTimeInfo,
                  ...editedZones.filter((item) => item.zone_number !== newRunTimeInfo.zone_number),
                ];
            
                setEditedZones(newArr);
        
                const deactivatedZone = {
                  ...zone,
                  decoder: "000000",
                  zone_name: t('Zone') + ` ${zone.id}`,
                  zone_wire: "1",
                  zone_type: '1',
                };
        
                const updatedZones = zones.map(z => 
                  z.id === zone.id ? deactivatedZone : z
                );
        
                setZones(updatedZones);

              }

            })
    
          }
         
            
         
         
     
  
        
        }
      });
    }
  };
  

  const saveChanges = async () => {
    setloadingBox(true);
    let body_request = {
      zones: editedZones,
    };
    

    try {
      const request = await apiUser.put(
        `/controllers/${location.state.id}/update_data/zones`,
        body_request
      );

      if (request.data.success) {
        setProgressBarColor("primary");
        setProgressBarSteps(0);

        setChangedZones(false);
        setEditedZones([]);
        setLoadingProgressBar(true);
      }

      const index = zones.findIndex((zone) => zone.id === selectedRow.id);

      // if (index !== -1) {
      //   const updatedZone = {
      //     ...zones[index],
      //     zone_name: formData.name,
      //     decoder: formData.decoder,
      //     zone_wire: formData.wire,
      //     zone_type: formData.Decodertype,
      //   };
      //   // console.log(updatedZone);
      //   const updatedZones = [...zones];
      //   updatedZones[index] = updatedZone;
      //   setZones(updatedZones);
      // }
    } catch (e) {
      console.error(e);
      if (e) {
        Swal.fire({
          title: "Error",
          text: t("Error, has been occurred."),
          icon: "warning",
          timer: 2000,
        })
       setloadingBox(false)
      }
    }
    setIsLoading(false);
    closebox();
  };

  const MobileTable = ({ data }) => (
    <Grid item container xs={12} justifyContent="center">
      {data.map((row) => (
        <Grid item xs={10} sm={8} key={`zone-mobile-table-${row.id}`}>
          <Accordion
            style={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid grey",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(5, 11, 0, 0.3)",
              marginBottom: "16px",
            }}
            key={`zone-list-item-${row.id}`}
          >
            <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
              <Grid item container xs={12}>
                <Grid item container xs={4}>
                  <Typography
                    style={{
                      display: "flex",
                      color: "#032C65",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      fontWeight: "bold",
                    }}
                  >
                    {row.zone_name ? row.zone_name : row.id}
                  </Typography>
                </Grid>
                <Grid item container xs={8}>
                  <Typography
                    style={{
                      display: "flex",
                      color: "#032C65",
                      flexDirection: "column",
                      fontFamily: "monospace",
                      alignItems: "flex-start",
                      fontWeight: "normal",
                    }}
                  >
                    {row.decoder}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              style={{
                color: "#032C65",
                fontWeight: "normal",
                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              <Typography
                style={{
                  color: "#032C65",
                  fontWeight: "normal",
                }}
              >
                {t(`Zone Name`)}:
              </Typography>
              <Typography
                style={{
                  color: "black",
                  marginRight: "8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >{`${row.zone_name || "N/A"}`}</Typography>
              <Typography
                style={{
                  color: "#032C65",
                  fontWeight: "normal",
                }}
              >
                {t(`Decoder S/N`)}
              </Typography>
              <Typography
                style={{
                  color: "black",
                  fontSize: "13px",
                  marginRight: "8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >{`${row.decoder || "N/A"}`}</Typography>
              <Typography
                style={{
                  color: "#032C65",
                  fontWeight: "normal",
                }}
              >
                {t(`Decoder Type`)}:
              </Typography>

              {row.decoder !== "000000" ? (
                <Chip
                  label={
                    row.zone_type === 1
                      ? t("Zone")
                      : row.zone_type === 2
                      ? "MV/PS"
                      : row.zone_type === 3
                      ? t("Flow meter")
                      : t("Moisture sensor")
                  }
                  size="small"
                  sx={{
                    backgroundColor:
                      row.zone_type === 1 ? green[100] : grey[200],
                    marginBottom: 2,
                  }}
                />
              ) : (
                <Chip
                  label="N/A"
                  size="small"
                  sx={{ backgroundColor: grey[200], color: grey[600] }}
                />
              )}
              <Typography
                style={{
                  color: "#032C65",
                  fontWeight: "normal",
                }}
              >
                {t(`Wire`)}:
              </Typography>
              <Typography
                style={{
                  color: "black",
                  fontSize: "13px",
                  marginRight: "8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >{`${row.zone_wire || "N/A"}`}</Typography>
              <Button
                size="large"
                style={{
                  backgroundColor: "#032C65",
                  border: "1px solid grey",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                  fontSize: "15px",
                  fontWeight: "normal",
                  color: "white",
                  borderRadius: "8px",
                }}
                startIcon={<EditIcon />}
                onClick={() => editZone(row)}
              >
                {t(`Edit`)}
              </Button>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );

  const TableZoneList = () => {
    const isSmallScreen = useMediaQuery("(max-width:800px)");

    const mapData = Object.keys(zones).map((key) => {
      const { decoder, zone_name, zone_wire, id, zone_type } = zones[key];

      return {
        id,
        decoder,
        zone_name,
        zone_wire,
        zone_type,
      };
    });

    return isSmallScreen ? (
      <MobileTable data={mapData} />
    ) : (
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <DataGrid
            style={{
              backgroundColor: "white",
              border: "2",
              borderRadius: "15px",
              boxShadow: "0 4px 8px rgba(5, 11, 0, 0.1)",
              fontSize: "15px",
              height: 700,
            }}
            hideFooter
            rows={zones}
            columns={columns}
            onSelectionModelChange={(newSelection) => {
              if (newSelection.selectionModel.length > 0) {
                const selectedRowIndex = newSelection.selectionModel[0];
                setSelectedRow(zones[selectedRowIndex]);
              }
            }}
            selectionModel={selectedRow ? [selectedRow.id] : []}
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingBox}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Grid container item xs={12} justifyContent="center">
        <Grid
          sx={{
            borderRadius: "8px",
            border: `1px solid ${grey[300]}`,
            backgroundColor: "#F7F8FA",
            marginBottom: 4,
            padding: 2,
          }}
          container
          xs={10}
          justifyContent="start"
        >
          <Grid
            sx={{ borderRadius: "8px" }}
            item
            container
            xs={12}
            sm={2}
            component={Stack}
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              size="large"
              sx={{ backgroundColor: "white" }}
              onClick={() =>
                navigate(`/dashboard/${location.state.id}`, {
                  state: location.state,
                })
              }
            >
              <ArrowBack />
            </IconButton>
          </Grid>

          <Grid
            item
            container
            elevation={0}
            xs={11}
            sm={10}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h5" fontWeight="bolder" color="#6B7A99">
              {t(`Zones`)}
            </Typography>

            <Grid item>
              <LoadingButton
                loading={loadingBox}
                className="button-new-controller-style"
                variant="contained"
                size="large"
                onClick={() => saveChanges()}
                disabled={!changedZones}
                sx={{
                  backgroundColor: "white",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  boxShadow: changedZones ? 4 : 0,
                  color: blueGrey[600],
                  borderRadius: 4,
                  "&:hover": {
                    backgroundColor: "white",
                    boxShadow: 6,
                    color: blue[600],
                  },
                }}
                startIcon={
                  !loadingBox && (
                    <SaveIcon
                      style={{
                        fontSize: 25,
                        color: changedZones === true ? yellow[600] : "white",
                      }}
                    />
                  )
                }
              >
                {t("Save")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        {loadingProgressBar && (
          <>
            <Grid item container xs={12} justifyContent="center">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {ProgressBarSteps == 0 &&
                  t("Initializing the update process...")}
                {ProgressBarSteps == 1 &&
                  t("Synchronizing with your device....")}
                {ProgressBarSteps == 2 &&
                  t("Applying changes to your device...")}
                {ProgressBarSteps == 3 &&
                  progressBarColor == "primary" &&
                  t("Finalizing changes to your controller...")}
                {ProgressBarSteps == 3 &&
                  progressBarColor == "success" &&
                  t("Zones are already updated.")}
              </Typography>
            </Grid>
            <LinearProgress
              sx={{ width: "83%", marginBottom: 2, borderRadius: 12 }}
              color={progressBarColor} // Aplicar el color según el estado
              variant="determinate"
              value={(ProgressBarSteps / 3) * 100} // Ajusta el valor según el número total de pasos
            />
          </>
        )}

        <Grid item container xs={12} justifyContent="center">
          {isLoading == true ? <SpinnerCircular /> : <TableZoneList />}
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={closebox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Grid container justifyContent="end">
            <CloseIcon
              size="large"
              color="primary"
              sx={{
                right: 5,
                top: "2%",
                cursor: "pointer",
              }}
              onClick={closebox}
            />
          </Grid>

          <div style={labelStyle}>
            {t(`Edit Zone`)}
            <br />
            <br />
          </div>

          <label
            style={{
              display: "block",

              color: "#032C65",
              fontWeight: "normal",
              marginBottom: "10px",
            }}
          >
            {t(`Name`)}:
          </label>
          <input
            type="text"
            placeholder={formData.name || ""}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            maxLength={20}
            style={{
              width: "95%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              textAlign: "center",
            }}
          />

          <label
            style={{
              display: "block",
              marginBottom: "10px",
              color: "#032C65",
              fontWeight: "normal",
              marginTop: "10px",
            }}
          >
            {t(`Decoder S/N`)}
          </label>
          <input
  placeholder={formData.decoder || ""}
  maxLength={6} 
  onChange={(e) => {
    const value = e.target.value;

    if (value.length <= 6) {
      setFormData({ ...formData, decoder: value });
    }
  }}
  onKeyDown={(e) => {
    if (!/[0-9]/.test(e.key) && !['Backspace', 'Tab'].includes(e.key)) {
      e.preventDefault(); 
    }
  }}
  style={{
    width: "95%",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    textAlign: "center",
  }}
/>

          <label
            style={{
              display: "block",
              marginBottom: "10px",
              color: "#032C65",
              fontWeight: "normal",
              marginTop: "10px",
            }}
          >
            {t(`Decoder type`)}:
          </label>
          <Select
            value={formData.zone_type || 1}
            style={{
              width: "100%",
              padding: "1px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              textAlign: "center",
              height: "38px",
            }}
          >
            <MenuItem
              value={1}
              onClick={() => setFormData({ ...formData, zone_type: 1 })}
            >
              {t(`Zone`)}
            </MenuItem>
            <MenuItem
              value={2}
              onClick={() => setFormData({ ...formData, zone_type: 2 })}
            >
              MV/PS
            </MenuItem>
            {/* <MenuItem
              value={3}
              onClick={() => setFormData({ ...formData, Decodertype: 3 })}
            >
              Flow meter
            </MenuItem>
            <MenuItem
              value={4}
              onClick={() => setFormData({ ...formData, Decodertype: 4 })}
            >
              Moisture sensor
            </MenuItem> */}
          </Select>

          <label
            style={{
              display: "block",
              marginBottom: "10px",
              color: "#032C65",

              fontWeight: "normal",
              marginTop: "10px",
            }}
          >
            {t(`Wire`)}:
          </label>
          <Select
            value={formData.wire || "N/A"}
            onChange={(e) => setFormData({ ...formData, wire: e.target.value })}
            style={{
              width: "100%",
              padding: "1px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              textAlign: "center",
              height: "38px",
            }}
          >
            <MenuItem
              value={1}
              onClick={(e) =>
                setFormData({ ...formData, wire: e.target.value })
              }
            >
              {t(`TW 1`)}
            </MenuItem>
            <MenuItem
              value={2}
              onClick={(e) =>
                setFormData({ ...formData, wire: e.target.value })
              }
            >
              {t(`TW 2`)}
            </MenuItem>
          </Select>

          <LoadingButton
            variant="contained"
            loadingPosition="start"
            onClick={() => confirmEditZoneTime()}
            style={{
              marginTop: "20px",
              width: "50%",
              backgroundColor: "#032C65",
              color: "white",
              fontWeight: "normal",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
            }}
          >
            {t(`Confirm`)}
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
};
